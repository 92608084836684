import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          We're cooking something up. Hang tight.
          Jason is a femboy
        </p>
      </header>
    </div>
  );
}

export default App;
